import { useEffect, useState, useContext } from 'react'
// prop-types is a library for typechecking of props
import PropTypes from 'prop-types'

// @mui material components
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import Collapse from '@mui/material/Collapse'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'

// Material Dashboard 2 React components
import MDInput from 'components/MDInput'
import MDButton from 'components/MDButton'
import VerticalBarChart from 'components/Charts/BarCharts/VerticalBarChart'

import useHttp from 'helpers/hooks/useHttp'
import { findNextRankBid, findNewRanking } from 'helpers/utils'

import { useAtom, useSetAtom, getAtom } from 'jotai'
import {
  expandedRowAtom,
  facilityDataAtom,
  changedMaxBidCountAtom,
  readySubmitAtom,
} from 'store/data'
import { portfolioDataAtom } from 'store/data'
import axiosClient from '../../../helpers/hooks/axiosClient'
import { AuthContext } from '../../../context/authContext';

function DataTableBodyCollapse({ index, row, isTargetRanking, setChangedMaxBids, tagFilter }) {
  const { auth } = useContext(AuthContext);
  const [expandedRow, setExpandedRow] = useAtom(expandedRowAtom)
  const [changedMaxBidCount, setChangedMaxBidCount] = useAtom(changedMaxBidCountAtom)
  const [readySubmit, setReadySubmit] = useAtom(readySubmitAtom)
  const [portfolioData, setPortfolioData] = useAtom(portfolioDataAtom)
  const facilityData = useAtom(facilityDataAtom)

  const rawData = row?.original?.raw_data

  const calcBidOnRank = (rank) => {
    if (!rank) return null
    return Math.max(
      rawData.city_bid_opportunity[`bid_for_${rank}`],
      rawData.zip_bid_opportunity[`bid_for_${rank}`]
    )
  }

  const isOpen = rawData?.facility?.facility_id === expandedRow
  const targetRank =
    rawData.facility.target_rank ||
    Math.max(rawData.city_bid_opportunity?.rank, rawData.zip_bid_opportunity?.rank)
  const estimatedBid = isTargetRanking ? calcBidOnRank(targetRank) : rawData.new_bid?.bid

  const [target_rank, setTargetRank] = useState(targetRank)
  const [value, setValue] = useState(rawData.facility.max_bid)
  const [bid, setBid] = useState(estimatedBid)
  const [error, setError] = useState(false)

  const setFacilityData = useSetAtom(facilityDataAtom)

  useEffect(() => {
    setValue(rawData.facility.max_bid)
  }, facilityData)

  useEffect(() => {
    setValue(row.original.raw_data.facility.max_bid)
  }, [row])

  const { fetchData, response: updateResponse } = useHttp({
    autoRun: false,
    method: 'put',
    url: '/facility/' + rawData?.facility?._id,
    headers: JSON.stringify({ accept: '*/*' }),
    body: JSON.stringify(isTargetRanking ? { target_rank: target_rank } : { max_bid: value }),
  })

  const saveBidHistory = (new_bid) => {
    let bids = JSON.parse(localStorage.getItem('bids'))
    if (!bids) bids = []

    const index = bids.findIndex((bid) => bid.facility_id === new_bid.facility_id)

    if (index !== -1) {
      bids[index] = new_bid
    } else {
      bids.push(new_bid)
    }
    localStorage.setItem('bids', JSON.stringify(bids))
  }

  const updateBid = () => {
    axiosClient.put('/bid/' + rawData?.new_bid?._id, { bid: bid })
  }

  const updateStrategy = (value) => {
    axiosClient
      .put('/portfolio', {
        current_strategy: value,
      })
      .then((result) => {
        setPortfolioData({
          ...portfolioData,
          current_strategy: value,
        })
      })
  }

  const handleChangeNewMaxBid = (e) => {
    let inputValue = e.target.value
    if (+inputValue > 10) inputValue = 10;

    setValue(inputValue)
  }

  const handleChangeTargetRank = (e) => {
    let inputValue = e.target.value
    inputValue = inputValue.replace(/[^0-9]/g, '')
    setTargetRank(inputValue)
    setBid(calcBidOnRank(inputValue))
  }

  const handleCancel = (e) => {
    setExpandedRow(null)
  }

  const handleSubmit = async (e) => {
    const result = Number.parseFloat(value / 0.05).toFixed(2)
    const regex = /^[0-9]+(\.00)?$/
    if (regex.test(result)) {
      await updateStrategy(isTargetRanking ? 'target_ranking' : 'facility_max_bid')
      setReadySubmit(false)
      setChangedMaxBidCount((changedMaxBidCount || 0) + 1)
      saveBidHistory({
        facility_id: rawData.facility.facility_id,
      })
      setChangedMaxBids(list=> [...list, rawData?.facility?.facility_id].filter(Boolean))
      fetchData()
      if (rawData.new_bid) updateBid()
      setExpandedRow(null)
      setError(false)
    } else {
      setError(true)
    }
  }

  const reloadFacilityData = () => {
    axiosClient.get(`/facility?filter_tags=${JSON.stringify(tagFilter)}`).then((result) => {
      setFacilityData(result.data.result)
    })
  }

  useEffect(() => {
    if (updateResponse) {
      // TODO: reload data.
      reloadFacilityData()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateResponse])

  if (!isOpen) return null

  const toDecimal = (x) => {
    if (typeof x === 'number' && !isNaN(x)) {
      return Number.parseFloat(x).toFixed(2)
    }
    return 'None'
  }

  const valueColor = (value) => {
    if (value <= 4.95) {
      return '#66BB6A'
    } else if (value > 4.95 && value <= 7.5) {
      return '#FFA726'
    } else {
      return '#EF5350'
    }
  }

  return (
    <TableRow key={`${index}_collapse`} {...row.getRowProps()}>
      <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={11}>
        <Collapse in={isOpen} timeout="auto" unmountOnExit>
          <Box sx={{ margin: 1 }}>
            <Grid container columnSpacing={4}>
              <Grid item xs={4} style={{ display: 'flex', flexDirection: 'column' }}>
                <Typography
                  sx={{ marginTop: 4, marginBottom: 4 }}
                  variant="h6"
                  component="h6"
                  align="center"
                  style={{ color: 'grey', fontWeight: '900', marginTop: 15, marginBottom: 15 }}
                >
                  Next Rank Insight
                </Typography>
                <Grid container style={{ border: '1px solid gray', borderRadius: '20px', flex: 1 }}>
                  <Grid item xs={8}>
                    <Typography
                      px={4}
                      mt={2}
                      sx={{ display: 'flex', alignItems: 'center', height: '60px' }}
                      variant="h6"
                      component="h6"
                      align="left"
                      style={{ color: 'grey' }}
                    >
                      Bid for Next City Rank
                    </Typography>
                    {/* <Typography px={4} pt={1} sx={{ display: 'flex', alignItems: 'center', height: '60px' }} variant="h6" component="h6" align="left" style={{ color: "grey" }}>
                      Increase in Reservation Probability City
                    </Typography> */}
                    <Typography
                      px={4}
                      pt={1}
                      sx={{ display: 'flex', alignItems: 'center', height: '60px' }}
                      variant="h6"
                      component="h6"
                      align="left"
                      style={{ color: 'grey' }}
                    >
                      Bid for Next Zip Rank
                    </Typography>

                    <Typography px={4} pt={1} sx={{ display: 'flex', alignItems: 'center', height: '60px' }} variant="h6" component="h6" align="left" style={{ color: 'grey' }}>
                      Bid for 1st City Rank
                    </Typography>
                    <Typography px={4} pt={1} sx={{ display: 'flex', alignItems: 'center', height: '60px' }} variant="h6" component="h6" align="left" style={{ color: 'grey' }}>
                      Bid for 1st Zip Rank
                    </Typography>
                    {/* <Typography px={4} pt={1} sx={{ display: 'flex', alignItems: 'center', height: '60px' }} variant="h6" component="h6" align="left" style={{ color: "grey" }}>
                      Increase in Reservation Probability Zip
                    </Typography> */}
                  </Grid>
                  <Grid item xs={4}>
                    <Typography
                      px={4}
                      mt={2}
                      sx={{ display: 'flex', alignItems: 'center', height: '60px' }}
                      variant="h6"
                      component="h6"
                      align="left"
                      style={{ color: 'grey' }}
                    >
                      {toDecimal(findNextRankBid(rawData.city_bid_opportunity))}
                    </Typography>
                    {/* <Typography px={4} pt={1} sx={{ display: 'flex', alignItems: 'center', height: '60px' }} variant="h6" component="h6" align="left" style={{ color: "grey" }}>
                      %
                    </Typography> */}
                    <Typography
                      px={4}
                      pt={1}
                      sx={{ display: 'flex', alignItems: 'center', height: '60px' }}
                      variant="h6"
                      component="h6"
                      align="left"
                      style={{ color: 'grey' }}
                    >
                      {toDecimal(findNextRankBid(rawData.zip_bid_opportunity))}
                    </Typography>

                    <Typography
                      px={4}
                      pt={1}
                      sx={{ display: 'flex', alignItems: 'center', height: '60px' }}
                      variant="h6"
                      component="h6"
                      align="left"
                      style={{ color: 'grey' }}
                    >
                      {toDecimal(rawData?.city_bid_opportunity?.bid_for_1)}
                    </Typography>
                    
                    <Typography
                      px={4}
                      pt={1}
                      sx={{ display: 'flex', alignItems: 'center', height: '60px' }}
                      variant="h6"
                      component="h6"
                      align="left"
                      style={{ color: 'grey' }}
                    >
                      {toDecimal(rawData?.zip_bid_opportunity?.bid_for_1)}
                    </Typography>
                    {/* <Typography px={4} pt={1} sx={{ display: 'flex', alignItems: 'center', height: '60px' }} variant="h6" component="h6" align="left" style={{ color: "grey" }}>
                      %
                    </Typography> */}
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={8}>
                <Typography
                  sx={{ marginTop: 4, marginBottom: 4 }}
                  variant="h6"
                  component="h6"
                  align="center"
                  style={{ color: 'grey', fontWeight: '900', marginTop: 15, marginBottom: 15 }}
                >
                  {isTargetRanking ? 'Rank Bid Impact' : 'Max Bid Impact'}
                </Typography>
                <Grid container style={{ border: '1px solid gray', borderRadius: '20px' }}>
                  <Grid item xs={7} style={{ padding: 10 }}>
                    <VerticalBarChart
                      description=""
                      chart={{
                        labels: [
                          isTargetRanking ? 'Estimated Bid' : 'Your Max Bid',
                          'Your Customer Lifetime Value',
                        ],
                        datasets: [
                          {
                            data: [isTargetRanking ? bid : value, rawData?.facility?.clv || portfolioData.clv || 0], // Adjust these values based on your data
                            color: [valueColor(isTargetRanking ? bid : value), '#49a3f1'],
                            borderWidth: 1,
                          },
                        ],
                      }}
                    />
                  </Grid>
                  <Grid item xs={5} style={{ padding: 20 }}>
                    {isTargetRanking ? (
                      <Grid container spacing={2}>
                        <Grid item xs={6}>
                          <Typography
                            sx={{ marginBottom: 3, height: '33%' }}
                            variant="h6"
                            component="h6"
                            align="left"
                            style={{ color: 'grey' }}
                          >
                            New Target Rank
                          </Typography>
                          <Typography
                            sx={{ marginBottom: 3, height: '33%' }}
                            variant="h6"
                            component="h6"
                            align="left"
                            style={{ color: 'grey' }}
                          >
                            Estimated Bid Required
                          </Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <Typography
                            sx={{ marginBottom: 3, height: '33%' }}
                            variant="h6"
                            component="h6"
                            align="right"
                            style={{ color: 'grey' }}
                          >
                            <MDInput
                              label="New Target Rank"
                              type="number"
                              onChange={(e) => handleChangeTargetRank(e)}
                              value={target_rank}
                            />
                          </Typography>
                          <Typography
                            sx={{ marginBottom: 3, height: '33%' }}
                            variant="h6"
                            component="h6"
                            align="right"
                            style={{ color: 'grey' }}
                          >
                            {bid}
                          </Typography>
                        </Grid>
                      </Grid>
                    ) : (
                      <Grid container spacing={2}>
                        {error && (
                          <Typography
                            variant="h6"
                            component="h6"
                            align="center"
                            style={{ color: 'red' }}
                          >
                            Your bid must be in an increment of 0.05. Please try again.
                          </Typography>
                        )}
                        <Grid item xs={6}>
                          <Typography
                            sx={{ marginBottom: 3, height: '33%' }}
                            variant="h6"
                            component="h6"
                            align="left"
                            style={{ color: 'grey' }}
                          >
                            New Max Bid
                          </Typography>
                          <Typography
                            sx={{ marginBottom: 3, height: '33%' }}
                            variant="h6"
                            component="h6"
                            align="left"
                            style={{ color: 'grey' }}
                          >
                            New Zip Rank
                          </Typography>
                          <Typography
                            sx={{ marginBottom: 3, height: '33%' }}
                            variant="h6"
                            component="h6"
                            align="left"
                            style={{ color: 'grey' }}
                          >
                            New City Rank
                          </Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <Typography
                            sx={{ marginBottom: 3, height: '33%' }}
                            variant="h6"
                            component="h6"
                            align="right"
                            style={{ color: 'grey' }}
                          >
                            <input
                              type="number"
                              step="0.05"
                              max={10}
                              onChange={(e) => handleChangeNewMaxBid(e)}
                              value={value}
                              style={{ width: '100%', padding: '5px' }}
                            />
                          </Typography>
                          <Typography
                            sx={{ marginBottom: 3, height: '33%' }}
                            variant="h6"
                            component="h6"
                            align="right"
                            style={{ color: 'grey' }}
                          >
                            {findNewRanking(value, rawData.zip_bid_opportunity)}
                          </Typography>
                          <Typography
                            sx={{ marginBottom: 3, height: '33%' }}
                            variant="h6"
                            component="h6"
                            align="right"
                            style={{ color: 'grey' }}
                          >
                            {findNewRanking(value, rawData.city_bid_opportunity)}
                          </Typography>
                        </Grid>
                      </Grid>
                    )}
                    <Grid container spacing={2} style={{ marginTop: 50 }}>
              <Grid item xs={12}>
                {auth.role !== 'global_admin' && <MDButton
                  sx={{ ml: 2, float: 'right' }}
                  variant="outlined"
                  color="error"
                  onClick={(e) => handleSubmit(e)}
                >
                  Save
                </MDButton>}
                <MDButton
                  color="secondary"
                  sx={{ float: 'right' }}
                  onClick={(e) => handleCancel(e)}
                >
                  Cancel
                </MDButton>
              </Grid>
            </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </Collapse>
      </TableCell>
    </TableRow>
  )
}

// Setting default values for the props of DataTableBodyCollapse
DataTableBodyCollapse.defaultProps = {
  collapse: false,
}

// Typechecking props for the DataTableBodyCollapse
DataTableBodyCollapse.propTypes = {
  index: PropTypes.node.isRequired,
  row: PropTypes.object.isRequired,
  collapse: PropTypes.bool,
}

export default DataTableBodyCollapse