import * as React from 'react';

// @mui material components
import IconButton from '@mui/material/IconButton';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import { findNewRanking } from 'helpers/utils';

import { useAtom } from 'jotai'
import { expandedRowAtom, changedMaxBidsAtom } from "store/data";

const checkIsMobileOrTablet = () => {
  if (window.innerWidth < 768) {
    return true;
  }
  return false;
}

export default function ProjectsTableData(facilityData, isTargetRanking) {
  const [expandedRow, setExpandedRow] = useAtom(expandedRowAtom)
  const [changedMaxBids] = useAtom(changedMaxBidsAtom);
  const toDecimal = (x) => {
    if (typeof x === 'number' && !isNaN(x)) {
      return Number.parseFloat(x).toFixed(2);
    }
    return ''
  }

  let hasOccupancy = false;

  if (facilityData && facilityData.length > 0) {
    const rows = facilityData.map(row => {
      const isBidUpdated = changedMaxBids?.includes(row.facility?.facility_id)
      
      const getPhysicalOccupancy = (opportunity) => {
        return opportunity?.facility_physical_occupancy && opportunity?.facility_physical_occupancy !== '-%' 
          ? opportunity.facility_physical_occupancy 
          : '-%';
      };

      let facilityPhysicalOccupany = getPhysicalOccupancy(row.city_bid_opportunity);
      if (facilityPhysicalOccupany === '-%') {
        facilityPhysicalOccupany = getPhysicalOccupancy(row.zip_bid_opportunity);
      }

      if (facilityPhysicalOccupany && facilityPhysicalOccupany !== '-%') {
        hasOccupancy = true;
        facilityPhysicalOccupany = Math.round(parseFloat(facilityPhysicalOccupany) * 100) + '%';
      }

      return {
        raw_data: row,
        facility_name: row?.facility?.alternate_name?.trim() || row.facility.facility_name,
        facility_city: row.facility.facility_city,
        facility_state: row.facility.facility_state,
        facility_zip: row.facility.facility_zip,
        facility_physical_occupancy: facilityPhysicalOccupany,
        c_rank: (
          row.city_bid_opportunity?.rank
        ),
        z_rank: (
          row.zip_bid_opportunity?.rank
        ),
        bid: (
          (row.city_bid_opportunity?.bid || row.zip_bid_opportunity?.bid)?.toFixed(2)
        ),
        target_rank: (
          row.facility.target_rank || Math.max(row.city_bid_opportunity?.rank, row.zip_bid_opportunity?.rank)
        ),
        max_bid: (
          row.facility.max_bid
        )?.toFixed(2),
        min_bid: (
          row.city_bid_opportunity?.min_bid || row.zip_bid_opportunity?.min_bid
        )?.toFixed(2),
        o_bid: (
          row.new_bid ? row.new_bid?.bid?.toFixed(2) : row.facility.max_bid?.toFixed(2)
        ),
        has_new_bid: row.new_bid ? true : false,
        diff_max_opt: (
          toDecimal((row.city_bid_opportunity?.bid || row.zip_bid_opportunity?.bid) - row.new_bid?.bid)
        ),
        oc_rank: (
          findNewRanking(row.new_bid?.bid, row.city_bid_opportunity)
        ),
        oz_rank: (
          findNewRanking(row.new_bid?.bid, row.zip_bid_opportunity)
        ),
        action: (
          (expandedRow !== row.facility.facility_id) ? (
            <MDButton sx={(theme) => ({
              padding: 1,
              [theme.breakpoints.only("xs")]: {
                fontSize: 10,
              }
            })}
              color="error" variant={isBidUpdated ? "contained" : "outlined"} onClick={(e) => {
                e.preventDefault();
                setExpandedRow(row.facility.facility_id);
              }}>
              {isTargetRanking ? 'Update Target Rank' : ""}
              {isBidUpdated ? 'Bid Updated' : 'Update Max Bid'}</MDButton>
          ) : (
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={(e) => {
                e.preventDefault();
                setExpandedRow(null);
              }}
            >
              <KeyboardArrowUpIcon />
            </IconButton>
          )
        ),
      }
    });

    let hiddenColumns = ['facility_city', 'facility_state', 'facility_zip'];

    if (checkIsMobileOrTablet()) {
      hiddenColumns = ['facility_city', 'facility_state', 'facility_zip', 'c_rank', 'z_rank', 'bid', 'target_rank', 'max_bid', 'min_bid', 'oc_rank', 'oz_rank'];
    }

    if (!hasOccupancy) {
      hiddenColumns = [...hiddenColumns, 'facility_physical_occupancy'];
    }

    return {
      columns: [
        { Header: "Facility", accessor: "facility_name", width: "10%", align: "left", sort: true},
        { Header: "City", accessor: "facility_city", width: "20%", align: "left", sort: true },
        { Header: "State", accessor: "facility_state", width: "20%", align: "left", sort: true },
        { Header: "Zip", accessor: "facility_zip", width: "20%", align: "left", sort: true },
        { Header: "Occupancy", accessor: "facility_physical_occupancy", align: "left", sort: true },
        { Header: "City Ranking", accessor: "c_rank", align: "left", sort: true, tooltip: "The current ranking of your facility in its city" },
        { Header: "Zip Ranking", accessor: "z_rank", align: "left", sort: true, tooltip: "The current ranking of your facility in its zip code" },
        { Header: "Bid", accessor: "bid", align: "left", sort: true, tooltip: "Your current SpareFoot bid" },
        ...(isTargetRanking ? [{ Header: "Target Rank", accessor: "target_rank", align: "left", sort: true }] : [{ Header: "Max Bid", accessor: "max_bid", align: "left", sort: true, tooltip: "Your upper limit or max threshold for your facility's bid" }]),
        { Header: "Min Bid", accessor: "min_bid", align: "left", sort: true, tooltip: "Your lower limit or min threshold for your facility's bid" },
        { Header: isTargetRanking ? "Estimated Bid" : 'Optimized Bid', accessor: "o_bid", align: "left", sort: true, tooltip: "The optimal bid determined by the BigFoot algorithm" },
        { Header: "Bid Delta", accessor: "diff_max_opt", align: "left", sort: true, tooltip: "= Current Bid - Optimized Bid" },
        { Header: "City Ranking", accessor: "oc_rank", align: "left", sort: true, tooltip: "The estimated ranking of your facility in its city after publishing the optimized bid" },
        { Header: "Zip Ranking", accessor: "oz_rank", align: "left", sort: true, tooltip: "The estimated ranking of your facility in its zip code after publishing the optimized bid" },
        { Header: "Actions", accessor: "action", align: "center" },
      ],
      rows: rows,
      initialState: {
        hiddenColumns: hiddenColumns,
      },
      max_bid: facilityData[0]?.facility?.max_bid,
      has_occupancy: hasOccupancy,
      account_name: facilityData[0].city_bid_opportunity?.account_name,
    };
  }

  return {
  };
}
